
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import LoadAccountBtn from "@/components/Pages/Home/Account/LoadAccount/LoadAccountBtn.vue";
import Validation from "@/components/Validation.vue";
import FaceConfused from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceConfused.vue";
import FaceSmileWink from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceSmileWink.vue";
import { namespace } from "s-vuex-class";
import isArray from "lodash/isArray";
import useVuelidate from "@vuelidate/core";
import { minValue, required } from "@vuelidate/validators";

const LivePayModule = namespace("live-pay");
const LoadAccountModule = namespace("load-account");
@Options({
  components: {
    FaceSmileWink,
    FaceConfused,
    LoadAccountBtn,
    EducationTabs,
    Validation,
  },

  validations: {
    amountInEur: { required, minValue: minValue(1) },
  },
})
export default class LoadAccountForm extends Vue {
  public v$ = useVuelidate();

  @LivePayModule.Action("create")
  public livePayAction: any;

  @LivePayModule.Action("resetSave")
  public resetSaveAction: any;

  @LivePayModule.Getter("getSuccess")
  public livePaySuccess: any;

  @LivePayModule.Getter("getIsLoading")
  public livePayLoading: any;

  @LoadAccountModule.Action("loadBalance")
  public loadBalanceAction: any;

  @LoadAccountModule.Action("setSelectedEducation")
  public selectEducationAction: any;

  @LoadAccountModule.Action("setSelectedEducationIndex")
  public selectEducationIndexAction: any;

  @LoadAccountModule.Getter("getBalanceSuccess")
  public balanceSuccess: any;

  @LoadAccountModule.Getter("getBalanceItem")
  public balance: any;

  @LoadAccountModule.Getter("getSelectedEducation")
  public selectedEducation: any;

  private selectedEducationIndex = 0;

  @Prop()
  public studentEducations!: any;

  public disablePayButton = false;

  public created() {
    if (this.studentEducations && this.selectedEducation) {
      this.selectedEducationIndex = this.studentEducations.findIndex((ed: any) => ed.id == this.selectedEducation?.id);
      this.selectedEducationIndex = this.selectedEducationIndex == -1 ? 0 : this.selectedEducationIndex;
    }
    this.resetSaveAction();
  }

  public beforeUnmount(): void {
    this.resetSaveAction();
  }

  private amountInEur: number | null = null;

  private onEducationClassChanged(educationClass: any): void {
    if (educationClass && educationClass.id) {
      this.selectEducationAction(educationClass);
      this.loadBalance(educationClass);
    }
  }

  protected validate(): boolean {
    //@ts-ignore
    this.v$.$touch();
    //@ts-ignore
    return !this.v$.$invalid;
  }

  private livePay(): void {
    const selectedEducationId = this.selectedEducation?.id;

    if (!selectedEducationId) return;
    const data = {
      studentEducationId: selectedEducationId,
      amountInEur: this.amountInEur,
      mandateId: this.selectedEducation.activeLivePayMandateId,
    };
    this.livePayAction({
      resource: "live-pay/payment/from-student",
      hideSuccessMessage: true,
      data: data,
    });
  }

  private loadBalance(studentEducation: any): void {
    if (!studentEducation || !studentEducation.id) {
      return;
    }
    this.loadBalanceAction(studentEducation);
  }

  private onLoadAccountClick(): void {
    if (!this.validate()) {
      return;
    }
    this.disablePayButton = true;
    setTimeout(() => {
      this.disablePayButton = false;
    }, 7000);
    this.livePay();
  }

  @Watch("livePaySuccess", { immediate: true, deep: true })
  private onLivePaySuccessChange(livePaySuccess: any): void {
    if (true === livePaySuccess) {
      this.$emit("onLoadAccount", this.amountInEur);
    }
  }

  @Watch("studentEducations", { immediate: true, deep: true })
  private onStudentEducationsChange(studentEducations: any): void {
    if (isArray(studentEducations) && studentEducations.length > 0 && !this.selectedEducation?.id) {
      this.selectEducationAction(studentEducations[0]);
    }
  }

  private onCollectMoneyClick(): void {
    this.$emit("onCollectMoney");
  }
}
