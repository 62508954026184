import { Options, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

const StudentEducationModule = namespace("student-education");

@Options({})
export default class StudentEducationsStoreMixin extends Vue {
  @StudentEducationModule.Action("findAll")
  public findStudentEducationAction: any;

  @StudentEducationModule.Getter("withTheory/getDataList")
  public studentEducations: any;

  @StudentEducationModule.Getter("withTheory/getIsLoading")
  public loadingStudentEducations: any;

  @StudentEducationModule.Action("withTheory/findAll")
  public findAllStudentEducationWithTheory!: (options: ICrudOptions) => Promise<void>;

  public async loadStudentEducationsWithTheory(): Promise<void> {
    await this.findAllStudentEducationWithTheory({
      resource: "/student-educations/with-theory/student/current",
      descriptionField: "",
    });
  }
}
