
import { Options, Prop, Vue } from "vue-property-decorator";
import LoadAccountForm from "@/components/Pages/Home/Account/LoadAccount/LoadAccountForm.vue";
import AccountLoaded from "@/components/Pages/Home/Account/LoadAccount/AccountLoaded.vue";
import CollectMoney from "@/components/Pages/Home/Account/LoadAccount/CollectMoney.vue";
import MoneyCollected from "@/components/Pages/Home/Account/LoadAccount/MoneyCollected.vue";
import { namespace } from "s-vuex-class";

const LoadAccountModule = namespace("load-account");

@Options({
  components: {
    MoneyCollected,
    CollectMoney,
    AccountLoaded,
    LoadAccountForm,
  },
})
export default class LoadAccountModal extends Vue {
  @Prop()
  public studentEducations!: any;

  @Prop({ default: 0 })
  public balance!: any;

  @LoadAccountModule.Action("reset")
  public resetAction: any;

  private showView = "load-account-form";

  public beforeUnmount(): void {
    this.resetAction();
  }
  private onLoadAccount(): void {
    this.showView = "account-loaded";
  }
  private showLoadAccountView(): void {
    this.showView = "load-account-form";
  }

  private showCollectMoneyView(): void {
    this.showView = "collect-money";
  }

  private onCollect(): void {
    this.showView = "money-collected";
  }
}
