
import { Options, Prop } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import LoadAccountBtn from "@/components/Pages/Home/Account/LoadAccount/LoadAccountBtn.vue";
import Validation from "@/components/Validation.vue";
import FaceConfused from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceConfused.vue";
import FaceSmileWink from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceSmileWink.vue";
import { mixins } from "vue-class-component";
import StudentEducationMixin from "@/mixins/StudentEducations/StudentEducationMixin";

@Options({
  components: {
    FaceSmileWink,
    FaceConfused,
    LoadAccountBtn,
    EducationTabs,
    Validation,
  },
})
export default class MoneyCollected extends mixins(StudentEducationMixin) {
  public name = "MoneyCollected";

  @Prop()
  public studentEducations!: any;
  @Prop({ default: 0 })
  public balance!: any;

  private onLoadAgainClick(): void {
    this.$emit("onLoadAgain");
  }

  private onCollectMoneyClick(): void {
    this.$emit("onCollectMoney");
  }
}
