export function getBrowser(): any {
  const ua = window.navigator.userAgent.toLowerCase().replace(/^mozilla\/\d\.\d\W/, "");
  // @ts-ignore
  if (window.navigator && window.navigator.userAgentData) {
    // @ts-ignore
    const { brands }: any = window.navigator.userAgentData;
    const browser: any = brands[brands.length - 1].brand;
    const browserVersion: any = brands[brands.length - 1].version;
    return { browser, browserVersion };
  }

  // // @ts-ignore
  const browserTest = ua.match(/(\w+)\/(\d+\.\d+(?:\.\d+)?(?:\.\d+)?)/g),
    browserOffset = browserTest?.length && (browserTest.length > 2 && !/^(ver|cri|gec)/.test(browserTest[1]) ? 1 : 0),
    browserResult = browserTest?.length && browserTest[browserTest.length - 1 - (browserOffset || 0)].split("/"),
    browser = browserResult && browserResult[0],
    version = (browserResult && browserResult[1]) || "";

  return {
    browser: getBrowserType(),
    browserVersion: version,
  };
}

export function getOS() {
  const userAgent = window.navigator.userAgent,
    macosPlatforms = ["macOS", "Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    platform = window.navigator.platform;
  const osAndVersion: string = window.navigator.userAgent || "";

  let os = "Unknown OS";

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  return {
    os,
    osAndVersion,
  };
}

export function getScreenSize(): string {
  const width: number = window.screen.width;
  const height: number = window.screen.height;
  const screenSize = width + " x " + height;
  return screenSize;
}

export function getBrowserType(): string {
  if (!isOpera()) {
    if (isEdge() || isEdgeChromium()) {
      return "Microsoft Edge";
    } else if (isChrome()) {
      return "Google Chrome";
    } else if (isFirefox()) {
      return "Mozilla Firefox";
    } else if (isSafari()) {
      return "Apple Safari";
    } else if (isInternetExplorer()) {
      return "Microsoft Internet Explorer";
    } else if (isUCBrowser()) {
      return "UC Browser";
    } else if (isSamsungBrowser()) {
      return "Samsung browser";
    } else {
      return "Unknown browser";
    }
  } else {
    return "Opera";
  }
}

export function isOpera() {
  //@ts-ignore
  return !!window?.opr || !!window?.opera || navigator.userAgent.toLowerCase().includes("opr/");
}

export function isFirefox() {
  //@ts-ignore
  return navigator.userAgent.toLowerCase().includes("firefox") || typeof InstallTrigger !== "undefined";
}

export function isSafari() {
  return navigator.userAgent.toLowerCase().includes("safari");
}

export function isInternetExplorer() {
  //@ts-ignore
  return false || !!document?.documentMode;
}

export function isEdge() {
  return !isInternetExplorer() && !!window.StyleMedia;
}

export function isChrome() {
  const userAgent = navigator.userAgent.toLowerCase();

  return userAgent.includes("chrome") || userAgent.includes("chromium") || userAgent.includes("crios");
}

export function isEdgeChromium() {
  return isChrome() && navigator.userAgent.includes("Edg");
}

export function isUCBrowser() {
  return navigator.userAgent.toLowerCase().includes("ucbrowser");
}

export function isSamsungBrowser() {
  return navigator.userAgent.toLowerCase().includes("samsungbrowser");
}
export function getManufacturer(): string {
  if (!window.navigator.userAgent) return "";
  return window.navigator.userAgent.split("(")[1].split(")")[0] || "";
}
