
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import Flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js";
import "flatpickr/dist/themes/airbnb.css";

@Options({
  components: {},
})
export default class FscDatepicker extends Vue {
  public name = "FscDatepicker";

  @Prop({ type: String, default: () => "date-picker" })
  public id!: string;

  @Prop({ type: String })
  public placeholder!: string;

  @Prop({ type: String, default: () => "de-DE" })
  public locale!: string;

  @Prop({ type: Boolean, default: () => false })
  public isInvalid!: boolean;

  @Prop({
    required: false,
    validator(value) {
      return (
        value === null ||
        value instanceof Date ||
        typeof value === "string" ||
        value instanceof String ||
        value instanceof Array ||
        typeof value === "number"
      );
    },
  })
  public modelValue!: any;

  @Prop({ required: false, default: () => false })
  public disabled!: any;

  @Prop({
    type: Number,
    default: () => 1,
  })
  public hourIncrement!: number;

  @Prop({
    type: Number,
    default: () => 5,
  })
  public minuteIncrement!: number;

  @Prop({
    type: String,
    default: () => "Y-m-d",
  })
  public dateFormat!: string;

  @Prop({
    type: String,
    default: () => "d.m.Y",
  })
  public altFormat!: string;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public twelveHourTime!: boolean;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public enableTime!: boolean;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public enableSeconds!: boolean;

  @Prop({
    type: Number,
    default: () => 1,
  })
  public firstDayOfWeek!: number;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  public noCalendar!: boolean;

  public flatpickr: any | null = null;

  public createFlatpickr(): void {
    Flatpickr.localize(German);
    const el = this.$refs.flatpickrRef as HTMLElement;

    this.flatpickr = Flatpickr(el, {
      enableTime: this.enableTime,
      enableSeconds: this.enableSeconds,
      //onOpen: this.onOpen,
      onClose: this.onClose,
      onChange: this.onChange,
      dateFormat: this.dateFormat,
      altInput: true,
      altFormat: this.altFormat,
      allowInput: true,
      time_24hr: !this.twelveHourTime,
      hourIncrement: this.hourIncrement,
      minuteIncrement: this.minuteIncrement,
      locale: { firstDayOfWeek: this.firstDayOfWeek },
      //clickOpens: false,
      altInputClass: "flatpickr-input",
      plugins: [],
      noCalendar: this.noCalendar,
    });

    if (this.modelValue) {
      this.flatpickr.setDate(this.modelValue);
    }

    if (this.disabled) {
      this.flatpickr.altInput.setAttribute("disabled", String(true));
    }
  }

  public mounted() {
    this.$nextTick(() => {
      this.createFlatpickr();
    });
  }

  public beforeUnmounted() {
    if (this.flatpickr) {
      this.flatpickr.destroy();
    }
  }

  public onClose(event: any, dateStr: string) {
    this.onChange(event, dateStr);
  }

  public onChange(event: any, dateStr: string) {
    this.$emit("update:modelValue", dateStr ? dateStr : null);
  }

  @Watch("modelValue")
  public onChangeValue(newValue: any): void {
    if (this.flatpickr) {
      this.flatpickr.setDate(newValue);
    }
  }

  @Watch("isInvalid", { immediate: true })
  public onChangeIsInvalid(newValue: boolean): void {
    if (this.flatpickr) {
      if (newValue) this.flatpickr.altInput.classList.add("is-invalid");
      else this.flatpickr.altInput.classList.remove("is-invalid");
    }
  }

  @Watch("disabled")
  public onChangeDisabled(newValue: any): void {
    if (newValue) {
      this.flatpickr.altInput.setAttribute("disabled", newValue);
    } else {
      this.flatpickr.altInput.removeAttribute("disabled");
    }
  }
}
