
import { Options, Prop, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import UserService from "@/services/UserService";
import { DEFAULT_DATE_FORMAT } from "@/config/Formats";
import { isNewDate } from "@/helper/DateUtil";

const BalanceModule = namespace("balance");
const EducationEventsModule = namespace("education-events");
@Options({
  components: {},
})
export default class AccountList extends Vue {
  private dateFormat = DEFAULT_DATE_FORMAT;

  @BalanceModule.Getter("getIsLoading")
  public balanceLoading: any;

  @EducationEventsModule.Action("findAll")
  public findEducationEventAction: any;

  @EducationEventsModule.Getter("getDataList")
  public educationEvents: any;

  @EducationEventsModule.Getter("getIsLoading")
  public educationEventsLoading: any;

  @Prop()
  public selectedEducation!: any;

  public mounted(): void {
    this.loadEducationEvents();
  }

  private loadEducationEvents(): void {
    this.findEducationEventAction({
      resource: "education-events",
      extraPath: "student/current",
      hideSuccessMessage: true,
    });
  }

  private downloadDocument(item: any): void {
    const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
    const token = UserService.getToken().replaceAll('"', "");
    const url = baseUrl + "/student-documents/student/current/document/" + item.studentDocumentId + "?token=" + token;
    window.open(url, "_blank");
  }

  private getAccountHistoryItemClass(item: any): string {
    let className = "bg-white font-color-monza-500 border-1-solid-trinidad-500";
    if (item.educationEventType === "LIVE_PAY_PAYMENT" || item.educationEventType === "CLASSIC_PAY_PAYMENT") {
      if (item.paymentFromThirdParty) {
        className = "bg-yellow-orange-500 text-white border-1-solid-yellow-orange-500";
      } else {
        className = "bg-color-lime-900 text-white border-1-solid-lime-900";
      }
      switch (item.livePayPaymentStatus) {
        case "PENDING":
          className = "bg-yellow-orange-500 text-white border-1-solid-yellow-orange-500";
          break;
        case "PENDING_SUBMISSION":
          className = "bg-yellow-orange-500 text-white border-1-solid-yellow-orange-500";
          break;
        case "CONFIRMED":
          className = "bg-yellow-orange-500 text-white border-1-solid-yellow-orange-500";
          break;
        case "PENDING_CUSTOMER_APPROVAL":
          className = "bg-yellow-orange-500 text-white border-1-solid-yellow-orange-500";
          break;
        case "CANCELLED":
        case "CUSTOMER_APPROVAL_DENIED":
        case "FAILED":
        case "CHARGED_BACK":
          className = "bg-monza-500 text-white border-1-solid-trinidad-500";
          break;
        default:
          className = "bg-color-lime-900 text-white border-1-solid-lime-900";
      }
    }
    if (
      (item.educationEventType === "PRACTICAL_EXAM" || item.educationEventType === "THEORY_EXAM" || item.educationEventType === "DRIVING_LESSON") &&
      item.amountToPay &&
      item.productBookingId
    ) {
      className = "bg-monza-500 text-white border-1-solid-trinidad-500";
    }
    return className;
  }

  private getStatus(item: any): string {
    if (isNewDate(item.date)) {
      return `(${this.$t("general.preview")})`;
    }
    switch (item.livePayPaymentStatus) {
      case "PENDING":
        return `(${this.$t("general.reserved")})`;
      case "PENDING_SUBMISSION":
        return `(${this.$t("general.reserved")})`;
      case "CONFIRMED":
        return `(${this.$t("general.reserved")})`;
      case "PENDING_CUSTOMER_APPROVAL":
        return `(${this.$t("general.reserved")})`;
      default:
        return "";
    }
  }
}
